import React, { Component } from 'react';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import PageHeading from '../../components/PageHeading/PageHeading';
import ResourceTable from '../../components/ResourceTable/ResourceTable';

class Bylaws extends Component {
  render() {
    const { data } = this.props;
    const { edges: resources } = data.allBylawsListJson;
    const mappedResources = resources.map(resource => resource.node);
    return (
      <Layout>
        <Helmet
          title="VEYM | Bylaws"
          meta={[
            {
              name: 'description',
              content: 'Bylaws of Vietnamese Eucharistic Youth Movement',
            },
          ]}
        />
        <PageHeading>Bylaws</PageHeading>
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/resources">Resources</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Bylaws</BreadcrumbItem>
          </Breadcrumb>
        </Container>
        <Container>
          <br />
          <h5>THƯ CÔNG BỐ NỘI QUY</h5>
          <p>
            Phong Trào Thiếu Nhi Thánh Thể Việt Nam được thành lập tại Hoa Kỳ
            vào năm 1984 tại thành phố New Orleans, tiểu bang Louisiana, trong
            thời điểm người công giáo tị nạn bắt đầu xây dựng cộng đồng công
            giáo trên xứ người. Lòng nhiệt thành hăng say dấn thân phục vụ không
            mỏi mệt của nhiều thành phần lãnh đạo các cấp và Huynh Trưởng đã tạo
            nên một mái ấm gia đình chứa chan tình yêu thương dân tộc, nuôi
            dưỡng giới trẻ đang bị lạc loài trên vùng đất tha phương xa lạ. Mái
            ấm gia đình Thiếu Nhi Thánh Thể đã bảo vệ che chở giới trẻ khỏi
            vướng vào những cạm bẫy tệ nạn của xã hội trong hoàn cảnh gặp khủng
            hoảng về ngôn ngữ và văn hóa.
          </p>
          <p>
            Hành trình nào cũng trải qua giây phút thử thách cam go để rồi được
            lớn mạnh và đạt đến mục đích. Phong Trào Thiếu Nhi Thánh Thể Việt
            Nam tại Hoa Kỳ đã trải qua một hành trình dài 35 năm kể từ ngày được
            thành lập tại New Orleans vào năm 1984. Dưới sự lãnh đạo của Thầy
            Giêsu Chí Thánh, thủ lãnh tối cao của Phong Trào, Phong Trào tiếp
            tục vững bước trong tin yêu và hy vọng để thực hiện sứ vụ loan báo
            Tin Mừng như lời bài hát Thiếu Nhi Tân Hành Ca mời gọi:{' '}
            <i>tuổi trẻ Việt Nam đem Chúa cho giới trẻ mọi nơi.</i>
          </p>
          <p>
            Đáp lại lời mời gọi truyền giáo này và cũng đáp lại nhu cầu mục vụ
            giới trẻ của thời đại, Phong Trào Thiếu Nhi Thánh Thể Việt Nam tại
            Hoa Kỳ bắt đầu ra đi để giúp các cộng đoàn của nhóm Á Châu và Thái
            Bình Dương, và các cộng đoàn người Nam Mỹ để thành lập TNTT tại các
            cộng đồng của họ. Trải qua 35 năm sinh hoạt không ngừng, Phong Trào
            TNTT tại Hoa Kỳ được coi là thành phần giới trẻ di dân năng động
            nhất trên nước Hoa Kỳ và được nhìn nhận là một đoàn thể có cơ cấu
            sinh hoạt tổ chức chặt chẽ nhất so với trên 80 nước trên thế giới có
            Thiếu Nhi Thánh Thể.
          </p>
          <p>
            Kề từ ngày thành lập cho đến nay đã có ba lần tu chính Nội Quy qua
            những năm 1990, 1993, và 2009. Sau 10 năm kể từ khi tu chính Nội Quy
            gần đây nhất là năm 2009, một lần nữa Phong Trào Thiếu Nhi Thánh Thể
            Việt Nam tại Hoa Kỳ tiếp tục tu chính Nội Quy để cho phù hợp với
            hoàn cảnh thực tại và để tiếp tục phát triển trong giai đoạn mới.
          </p>
          <p>
            Lần tu chính này có thêm vào một số luật mới và có nhiều thay đổi
            đáng kể so với các lần tu chính trước, rõ nét nhất là việc đơn giản
            hóa về cơ cấu tổ chức hàng dọc. <b>Trước tiên</b>, Hội Đồng Trung
            Ương được lấy ra khỏi hệ thống tổ chức của Phong Trào.{' '}
            <b>Thứ hai</b>, hệ thống Miền được thay thế bằng hệ thống Liên Đoàn
            và do đó một số ít địa dư được phân chia lại cho phù hợp với sơ đồ
            mới. <b>Thứ ba</b>, sơ đồ Ban Nghiên Huấn (BNH) được thêm vào và
            việc tuyển chọn và sắp xếp các thành viên trong BNH theo hệ thống
            hàng đội để gia tăng hiệu quả trong hoạt động. <b>Thứ tư</b>, vai
            trò Trợ Tá được thêm vào trong vị trí của Ban Chấp Hành đoàn. Ngoài
            những điều thay đổi lớn nói trên, một số điều nho nhỏ của các điều
            khoản cũng như phần vai trò, trách nhiệm và sự bổ nhiệm của các chức
            vụ các cấp được bổ sung thêm cho mạch lạc và tạo thuận lợi trong
            việc quản trị.
          </p>
          <p>
            Sau hơn ba năm từ ngày họp Hội Đồng Lãnh Đạo tại Atlanta, Georgia,
            từ ngày 5 đến 7 tháng 11 năm 2015 và ra Nghị Quyết Tu Chính Nội Quy,
            Hội Đồng Trung Ương đã duyệt qua các điều khoản tu chính và Hội Đồng
            Lãnh Đạo đã phê chuẩn, nay chúng tôi hân hoan giới thiệu và công bố
            cuốn Nội Quy 2019 để được áp dụng. Ước mong rằng cuốn Nội Quy này
            mang lại nhiều lợi ích cho mọi sinh hoạt trong Phong Trào Thiếu Nhi
            Thánh Thể Việt Nam tại Hoa Kỳ.
          </p>
          <p>Garden Grove, CA, ngày 1 tháng 3 năm 2019</p>
          <p>
            Lm. Fx. Nguyễn Thanh Bình, SVD
            <br />
            Tổng Tuyên Úy PT/TNTT/VN/HK
          </p>
          <br />
          <br />
        </Container>
        <Container>
          <ResourceTable resources={mappedResources} />
        </Container>
      </Layout>
    );
  }
}

export default Bylaws;

export const pageQuery = graphql`
  query BylawsQuery {
    allBylawsListJson {
      edges {
        node {
          name
          type
          links {
            fileType
            fileLink
          }
          categoryStyle {
            background
            border
          }
          categoryTextStyle {
            color
          }
          updated
        }
      }
    }
  }
`;

export const frontmatter = {
  title: 'Bylaws',
  category: 'resources',
  path: '/resources/bylaws',
  description: 'Bylaws',
  tags: 'resource, bylaws, noi quy 2019',
};
